import React from 'react'
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, TextStyled } from '../../../ui/Elements'
import { AboutImg, ArrowRight } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import { useTranslation } from 'react-i18next'
import Ticket from '../ticket/Ticket'

const About = () => {
    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+966535316584';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <>
            <AboutWapper className='container-fluid common-space aboutFluid' id='about'>
                <Container>
                    <Box className='about-text-card row'>
                        <Col lg={6} md={12}>
                            <LazyImage src={AboutImg} />
                        </Col>
                        <Col lg={6} md={12}>
                            <MainHeading className='main-heading tStripe mb-3'>{t('explore')} <MainHeading color='var(--themeColor)'> {t('skyWorld')}</MainHeading></MainHeading>
                            <TextStyled>
                                {t('welcome')}
                            </TextStyled>
                            <TextStyled>
                                {t('discoverWhat')}
                            </TextStyled>
                            <ContactButton icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                        </Col>
                    </Box>
                    <Ticket />
                </Container>
            </AboutWapper>
        </>
    )
}
const AboutWapper = styled.section`
    padding-bottom: 0;
    .about-text-card{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div:first-child{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }
    }
`
export default About
