import React, { Component, useEffect, useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { ArrowRight } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import { Box } from '../../../ui/Elements'
import { useTranslation } from 'react-i18next'

const AsNavFor = () => {
    const { t } = useTranslation();
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null); // Define slider1
    const [slider2, setSlider2] = useState(null); // Define slider2

    const WhatsApp = () => {
        const phoneNumber = '+966535316584';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);

    const SliderSetting = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        swipe: false,
    };

    const SliderSettingTh = {
        dots: false,
        infinite: true,
        nextArrow: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: false,
        pauseOnHover: false,
        // draggable: false,
        swipe: false,
        // touchMove: false,
        // draggable: false,
        // accessibility: false,

        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                
              }
            },
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  className: "center",
                    centerMode: true,
                  
                }
            }

          ]
    };

    return (
            <>
                <BannerWapper className='container-fluid banner-bg' id='home'>
                    <Box className='banner-wapper-card'>
                        
                        <Slider {...SliderSetting}
                            asNavFor={nav2}
                            ref={(slider) => (setSlider1(slider))}
                            >
                            <Box className='container-fluid mainSlide londonSlide'>
                                <Container className='containerMainSlider'>
                                    <Box className='sliderContentWrapper'>
                                        <Box className='sliderHeading'>
                                            {t('Start_your_journey')}
                                        </Box>
                                        <Box className='sliderdetail'>
                                            {t('sliderSubHeading')}
                                        </Box>
                                        <ContactButton className='sliderBtn' icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                                    </Box>
                                </Container>
                            </Box>
                            <Box className='container-fluid mainSlide dubaiSlide'>
                                <Container className='containerMainSlider'>
                                    <Box className='sliderContentWrapper'>
                                        <Box className='sliderHeading'>
                                            {t('Start_your_journey')}
                                        </Box>
                                        <Box className='sliderdetail'>
                                            {t('sliderSubHeading')}
                                        </Box>
                                        <ContactButton className='sliderBtn' icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                                    </Box>
                                </Container>
                            </Box>
                            <Box className='container-fluid mainSlide malaysiaSlide'>
                                <Container className='containerMainSlider'>
                                    <Box className='sliderContentWrapper'>
                                        <Box className='sliderHeading'>
                                            {t('Start_your_journey')}
                                        </Box>
                                        <Box className='sliderdetail'>
                                            {t('sliderSubHeading')}
                                        </Box>
                                        <ContactButton className='sliderBtn' icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                                    </Box>
                                </Container>
                            </Box>
                            <Box className='container-fluid mainSlide maldivSlide'>
                                <Container className='containerMainSlider'>
                                    <Box className='sliderContentWrapper'>
                                        <Box className='sliderHeading'>
                                            {t('Start_your_journey')}
                                        </Box>
                                        <Box className='sliderdetail'>
                                            {t('sliderSubHeading')}
                                        </Box>
                                        <ContactButton className='sliderBtn' icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                                    </Box>
                                </Container>
                            </Box>
                            <Box className='container-fluid mainSlide italySlide'>
                                <Container className='containerMainSlider'>
                                    <Box className='sliderContentWrapper'>
                                        <Box className='sliderHeading'>
                                            {t('Start_your_journey')}
                                        </Box>
                                        <Box className='sliderdetail'>
                                            {t('sliderSubHeading')}
                                        </Box>
                                        <ContactButton className='sliderBtn' icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                                    </Box>
                                </Container>
                            </Box>
                            <Box className='container-fluid mainSlide franceSlide'>
                                <Container className='containerMainSlider'>
                                    <Box className='sliderContentWrapper'>
                                        <Box className='sliderHeading'>
                                            {t('Start_your_journey')}
                                        </Box>
                                        <Box className='sliderdetail'>
                                            {t('sliderSubHeading')}
                                        </Box>
                                        <ContactButton className='sliderBtn' icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                                    </Box>
                                </Container>
                            </Box>
                            
                        </Slider>
                        <Box className='thumbnailSlides'>
                            <Slider {...SliderSettingTh}
                                asNavFor={nav1}
                                ref={(slider) => (setSlider2(slider))}
                                slidesToShow={3}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                >
                                <Box className='container-fluid mainThumb londonThumb'>
                                    <Container>
                                        <Box className='sliderThumbWrapper'>
                                            
                                            <Box className='sliderThumbDetail'>
                                                {t('TowerBridge')}
                                            </Box>
                                            <Box className='sliderThumbHeading'>
                                                {t('London')}      
                                            </Box>
                                        </Box>
                                    </Container>
                                </Box>
                                <Box className='container-fluid mainThumb dubaiThumb'>
                                    <Container>
                                        <Box className='sliderThumbWrapper'>
                                            
                                            <Box className='sliderThumbDetail'>
                                            {t('BurjKhalifa')}
                                            </Box>
                                            <Box className='sliderThumbHeading'>
                                            {t('Dubai')}
                                            </Box>
                                        </Box>
                                    </Container>
                                </Box>
                                <Box className='container-fluid mainThumb malaysiaThumb'>
                                    <Container>
                                        <Box className='sliderThumbWrapper'>
                                            
                                            <Box className='sliderThumbDetail'>
                                            {t('TwinTowers')}
                                            </Box>
                                            <Box className='sliderThumbHeading'>
                                            {t('Malaysia')}
                                            </Box>
                                        </Box>
                                    </Container>
                                </Box>
                                <Box className='container-fluid mainThumb maldivThumb'>
                                    <Container>
                                        <Box className='sliderThumbWrapper'>
                                            
                                            <Box className='sliderThumbDetail'>
                                            {t('ReethiBeach')}
                                            </Box>
                                            <Box className='sliderThumbHeading'>
                                            {t('Maldives')}
                                            </Box>
                                        </Box>
                                    </Container>
                                </Box>
                                <Box className='container-fluid mainThumb italyThumb'>
                                    <Container>
                                        <Box className='sliderThumbWrapper'>
                                            
                                            <Box className='sliderThumbDetail'>
                                            {t('CanalCity')}
                                            </Box>
                                            <Box className='sliderThumbHeading'>
                                            {t('Italy')}
                                            </Box>
                                        </Box>
                                    </Container>
                                </Box>
                                <Box className='container-fluid mainThumb franceThumb'>
                                    <Container>
                                        <Box className='sliderThumbWrapper'>
                                            
                                            <Box className='sliderThumbDetail'>
                                            {t('EiffelTower')}
                                            </Box>
                                            <Box className='sliderThumbHeading'>
                                            {t('France')}
                                            </Box>
                                        </Box>
                                    </Container>
                                </Box>
                                
                            </Slider>
                        </Box>
                        
                        
                        
                    </Box>
                    
                </BannerWapper >
            </>
        );
    };
    
export default AsNavFor;
    
const BannerWapper = styled.section`
    .banner-wapper-card{
        position: relative;
        height: 100%;
    }
    .banner-card{
        padding - top:70px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .banner-text-card{
        display: flex;
        flex-direction: column;
        gap:20px;
        width: 56%;
    }

`


