import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Col, Row, Form, Modal } from 'react-bootstrap'
import { ContactButton, InputWithIcon } from '../../../ui/CustomElements'
import { Box, LabelStyled, LazyImage, TextStyled } from '../../../ui/Elements'
import { ArrowRight, SuccessLogo } from '../../../ui/AllImages'
import axios from 'axios'
import { useTranslation } from 'react-i18next'


const ContactForm = () => {
    const { t, i18n } = useTranslation();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);


    const getForm = async () => {
        try {
            const response = await axios.post("https://sahabalalam.sa/api/public/api/contact-us", { ...formData, language: i18n.language })
            if (!response.data) {
                throw new Error('Response error plz check the response');
            }
            else {
                handleShowSuccessModal();
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    departure: "",
                    destination: "",
                    travel_date: "",
                    no_of_travelers: "",
                    notes: "",
                });
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    const [Errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        departure: "",
        destination: "",
        travel_date: "",
        no_of_travelers: "",
        notes: "",
    });
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        departure: "",
        destination: "",
        travel_date: "",
        no_of_travelers: "",
        notes: "",

    });
    const FormHandle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData((list) => {
            return { ...list, [name]: value }
        })

        setErrors((errors) => {
            return { ...errors, [name]: '' };
        });
    }

    const validateForm = () => {
        const errors = {};
        if (formData.name.trim() === "") {
            errors.name = t('Name_is_required');
        }
        if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
            errors.email = t('Invalid_email_address');
        }
        if (formData.phone.trim() === "" || !isValidPhone(formData.phone)) {
            errors.phone = t('Invalid_phone_number');
        }
        if (formData.departure.trim() === "") {
            errors.departure = t('departure_is_required');
        }
        if (formData.destination.trim() === "") {
            errors.destination = t('destination_is_required');
        }
        if (formData.travel_date.trim() === "") {
            errors.travel_date = t('travelDate_is_required');
        }
        if (formData.no_of_travelers.trim() === "") {
            errors.no_of_travelers = t('travellers_is_required');
        }
        if (formData.notes.trim() === "") {
            errors.notes = t('addNotes_is_required');
        }
        
        
        
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const isValidPhone = (phone) => {
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            getForm();
            console.log(formData);
        }
    };

    return (
        <ContacFormWapper className='contactFormWrapper'>
            <Box>
                <Form onSubmit={handleSubmit}>
                    <Row className='mb-5'>
                        <Col lg={12} className='py-2 inputCol'>
                            <LabelStyled className='pt-2 pb-1'>{t('Name')}</LabelStyled>
                            <InputWithIcon type='text' name='name' value={formData.name} placeholder={t('NameP')}  onChange={FormHandle}/>
                            <TextStyled className="error-message">{Errors.name}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='py-2 inputCol'>
                            <LabelStyled className='pt-2 pb-1'>{t('Email')}</LabelStyled>
                            <InputWithIcon type='text' name='email' value={formData.email} placeholder={t('EmailP')} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.email}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='py-2 inputCol'>
                            <LabelStyled className='pt-2 pb-1'>{t('Phone')}</LabelStyled>
                            <InputWithIcon type='text' name='phone' value={formData.phone} placeholder={t('PhoneP')} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.phone}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='py-2 inputCol'>
                            <LabelStyled className='pt-2 pb-1'>{t('Departure')}</LabelStyled>
                            <InputWithIcon type='text' name='departure' value={formData.departure} placeholder={t('DepartureP')} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.departure}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='py-2 inputCol'>
                            <LabelStyled className='pt-2 pb-1'>{t('Destination2')}</LabelStyled>
                            <InputWithIcon type='text' name='destination' value={formData.destination} placeholder={t('DestinationP')} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.destination}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='py-2 inputCol'>
                            <LabelStyled className='pt-2 pb-1'>{t('TravelDate')}</LabelStyled>
                            <InputWithIcon type='date' id="datepick" name='travel_date' value={formData.travel_date} placeholder={t('TravelDateP')} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.travel_date}</TextStyled>
                        </Col>
                        <Col lg={6} md={12} className='py-2 inputCol'>
                            <LabelStyled className='pt-2 pb-1'>{t('NumberTravelers')}</LabelStyled>
                            <InputWithIcon type='number'  min="1" name='no_of_travelers' value={formData.no_of_travelers} placeholder={t('NumberTravelers')} onChange={FormHandle} />
                            <TextStyled className="error-message">{Errors.no_of_travelers}</TextStyled>
                        </Col>
                        
                        <Col md={12} className='py-2 inputCol textareaCol'>
                            <LabelStyled className='pt-2 pb-1'>{t('AdditionalNotes')}</LabelStyled>
                            <Box className='textarea-box'>
                                <textarea cols="" rows="6" name='notes' value={formData.notes} placeholder={t('AdditionalNotes')} onChange={FormHandle}></textarea>
                                
                            </Box>
                            <TextStyled className="error-message">{Errors.notes}</TextStyled>
                        </Col>
                        <ContactButton type="submit" className="my-3" content='end' icon={ArrowRight} label={t('Send')} />
                    </Row>
                </Form>
            </Box>
            {/* <Model /> */}
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <LazyImage src={SuccessLogo} />
                    <TextStyled>{t('Message_Sent_successfully')}</TextStyled>
                </Modal.Body>
                <Modal.Footer>
                    <ContactButton className="my-1" content='end' label={t('Close')} onClick={handleCloseSuccessModal} />
                </Modal.Footer>
            </Modal>
        </ContacFormWapper >
    )
}
const ContacFormWapper = styled.div`
    margin:30px 0;
`

export default ContactForm
