import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, TextStyled } from '../../../ui/Elements'
import { Contact1, Contact2 } from '../../../ui/AllImages'
import ContactForm from './ContactForm'
import Map from './Map'
import { useTranslation } from 'react-i18next'


const Contact = () => {
    const { t } = useTranslation()
    return (
        <ContactWrapper className='container-fluid common-space contactFluid' id='contact'>
            <Container>
                <Box className=''>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading contactHeading'>
                        <MainHeading className='py-2 main-text main-heading' color='var(--themeColor)'>{t('Contactus')} <MainHeading color='var(--darkGrey)'> {t('Wearehere')}</MainHeading></MainHeading>
                    </Box>
                    <TextStyled className='contactDesc'>
                        {t('Doyouhavequestion')}
                    </TextStyled>
                </Box>
                <Box className='contactContainer'>
                        <Box className='contactImg contactImg1'>
                            <LazyImage src={Contact1} />
                        </Box>
                        <Box className='contactImg contactImg2'>
                            <LazyImage src={Contact2} />
                        </Box>
                    <Row className='contactRow'>
                        <Col lg={6}>
                            <Map />
                        </Col>
                        <Col lg={6}>
                            <ContactForm />
                        </Col>
                        
                    </Row>
                </Box>
                
                
            </Container>
        </ContactWrapper>

    )
}
const ContactWrapper = styled.section`
    
    
`

export default Contact
