import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Box, LazyImage, SpanStyled } from '../Elements'
import { FooterBg, FooterLogo, Instagram, Snapchat, Tiktok, Twitter } from '../AllImages'
import { NavDataConfig } from '../../components/projectData/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import ChangeButton from '../../Languages/ChangeButton'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const date_year = new Date();
    const SocialLinkTiktok = () => {
        window.open('https://www.tiktok.com/@skyworld_7?_t=8hdTgbvV7CC&_r=1', '_blank');
    };
    const SocialLinkSnapchat = () => {
        window.open('https://www.snapchat.com/add/sahab-alalam', '_blank');
    };
    const SocialLinkInstagram = () => {
        window.open('https://instagram.com/sahabalalam.sa?igshid=NGVhN2U2NjQ0Yg==', '_blank');
    };
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    return (
        <FooterWapper className='container-fluid footerFluid'>
            <Container>
                <Box className='footer-BG'>
                    <LazyImage className='footer-vector-img' src={FooterBg} />
                </Box>
                <Row>
                    <Box className='footerContentContainer'>
                        <Box className='footerLogo'>
                            <LazyImage src={FooterLogo} />
                        </Box>

                        <Box className='Footer-social-links'>
                            <Box className='d-flex align-items-center gap-3 mb-3 socialLinksContainer'>
                                <Box className='socialLinks'><Tiktok onClick={SocialLinkTiktok} /></Box>
                                <Box className='socialLinks'><Snapchat onClick={SocialLinkSnapchat} /></Box>
                                <Box className='socialLinks'><Instagram onClick={SocialLinkInstagram} /></Box>
                            </Box>
                            <Box className='d-flex flex-column footer-list-container'>
                                <Box className='footer-list'>
                                    {NavDataConfig.map((item, key) => (
                                        <Box key={key}>
                                            <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500} >{t(item.label)} </Link>
                                        </Box>
                                    ))}
                                </Box>
                                <SpanStyled className='copyRightDes mt-2' size='13px' color='var(--themeColor)' transform='capitalize'>&copy; {date_year.getFullYear()} {t('All_Rights_Reserved')}</SpanStyled>
                                <SpanStyled className='copyRightDes mt-2' size='13px' color='var(--themeColor)' transform='capitalize'>{t('Designed_Developed')} <Link onClick={FowlWeb} className='fLink'>{t('Fowl')}.</Link></SpanStyled>
                            </Box>
                            
                        </Box>
                        
                    </Box>
                </Row>
            </Container>
            <ChangeButton />
        </FooterWapper>
    )
}
const FooterWapper = styled.footer`
    position:relative;
    padding: 0px 0 0 0px;
    .container{
        position: relative;
    }
    .footer-BG{
        position: absolute;
        bottom: 0px;
        right: 0;
    }
    .footer-list{
        display:flex;  
        align-items: center;
        gap: 25px;
        & a{
            background: var(--gradientBlue);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: var(--medium);
            padding-bottom: 5px;
            
        }
    }
    .socialLinks{
        cursor: pointer;
    }
    .fLink{
        text-decoration: none;
        font-family: var(--medium);
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }

`
export default Footer
